import { Component, Input } from "@angular/core";
import {
  SmartMovingIntegration,
  NotificationIntegrationType
} from "../../../../../../../server/src/db/classes/notification-integration.type";
import { SourceConfigDialogData } from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import { DataService } from "src/app/Services/data.service";
import { IntegrationService } from "src/app/Services/integration.service";
import { OperationConfigType } from "../../../../../../../server/src/db/classes/operation-config.type";
import { SmartMovingIntegrationService } from "src/app/Services/smart-moving-integration.service";

@Component({
  selector: "app-smart-moving-integration",
  templateUrl: "./smart-moving-integration-config.component.html",
  styleUrl: "./smart-moving-integration-config.component.scss"
})
export class SmartMovingIntegrationComponent {
  @Input() integration: SmartMovingIntegration;
  @Input() sourceConfig: SourceConfigDialogData;
  public isConfirmDelete: boolean = false;
  public newapiKey = "";
  public createCustomerWithPhone = false;
  public isLodaing = false;

  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService,
    private smartMovingIntegrationService: SmartMovingIntegrationService
  ) {
    console.log("-----");
  }

  private get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async toggleEnable(newStatus: boolean): Promise<void> {
    await this.integrationService.toggleEnable(
      this.operationConfigId,
      NotificationIntegrationType.SmartMoving,
      this.sourceConfig.type,
      newStatus
    );

    this.integration.enabled = newStatus;
  }

  public confirmDelete(): void {
    this.isConfirmDelete = true;
  }

  public cancelDelete(): void {
    this.isConfirmDelete = false;
  }

  public async deleteApiKey(): Promise<void> {
    const result = await this.integrationService.removeAccessToken(
      this.operationConfigId,
      NotificationIntegrationType.SmartMoving
    );

    if (result) {
      this.integration.accessToken = null;

      this.toggleEnable(false);
    } else {
      this.cancelDelete();
    }
  }

  public async addCreds(): Promise<void> {
    this.isLodaing = true;
    let providerKey = this.newapiKey;
    const match = providerKey.match(/[?&]providerKey=([^&]+)/);
    providerKey = match ? match[1] : providerKey;
    try {
      const result = await this.smartMovingIntegrationService.manageApiKey(
        this.operationConfigId,
        this.sourceConfig.type,
        providerKey,
        this.createCustomerWithPhone
      );
      if (result) {
        this.integration.accessToken = result.accessToken;
        this.toggleEnable(true);
      }
    } catch (e) { }
    this.isLodaing = false;
  }

  public toggleCreateLeads(): void {
    this.createCustomerWithPhone = !this.createCustomerWithPhone;
  }
}
